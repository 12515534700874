import { render, staticRenderFns } from "./tomador.vue?vue&type=template&id=280d4c56"
import script from "./tomador.vue?vue&type=script&lang=js"
export * from "./tomador.vue?vue&type=script&lang=js"
import style0 from "./tomador.vue?vue&type=style&index=0&id=280d4c56&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports